<style scoped>
.c-progress-button {
  opacity: unset !important;
  cursor: not-allowed;
}
@media (max-width: 1024px) {
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
</style>
<script>

import {get_acsrf_token} from "@/methods";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {mapGetters} from "vuex";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  props: ['plan', 'planInfo'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
	  HalfCircleSpinner,
  },
  validations: {

  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
      'getDTLocale'
    ])
  },
  methods: {
    canContinue() {
      return (this.termsAccepted && this.withdrawalWaived);
    },
    async doContinue() {
      if(!this.canContinue()) return;
      this.processing = true;
      this.$emit('frameCompleted');
    }
  },
  created() {

  },
  async mounted() {
    if(this.planInfo.instant_activation) {
      this.withdrawalWaived = true;
    }

    this.$emit('frameReady');
  },
  destroyed() {

  },
  data() {
    return {
      ready: true,
      error: false,
      processing: false,
      termsAccepted: false,
      withdrawalWaived: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-sm-12">
          <div class="card card-body shadow shadow-lg text-center">
            <h3 class="mb-1">
              {{ planInfo.name }}
            </h3>
            <h1 class="mb-1">
              {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(planInfo.cost) }}
            </h1>
            <h5 class="text-muted">
              {{$t('billing.subscription.plans.period.monthly')}}
            </h5>

            <template v-if="planInfo.change">
              <hr>
              <div>
                <h4 class="mb-2">
                  <template v-if="planInfo.change.is_upgrade">
                    <small class="badge badge-primary small">
                      {{ $t('billing.checkout.summary.change.upgrade') }}
                    </small>
                  </template>
                  <template v-else>
                    <small class="badge badge-warning text-black small">
                      {{ $t('billing.checkout.summary.change.downgrade') }}
                    </small>
                  </template>
                  <br>
                  {{ $t('billing.checkout.summary.change.info', {previous_plan: planInfo.change.current_plan.name, new_plan: planInfo.name}) }}
                  <div class="small text-muted">
                    <template v-if="planInfo.change.effective_date">
                      {{ $t('billing.checkout.summary.change.date', {date: $d(parseDate(planInfo.change.effective_date), 'datetime', getDTLocale())}) }}
                    </template>
                    <template v-else>
                      {{ $t('billing.checkout.summary.change.date_now') }}
                    </template>
                  </div>
                </h4>
              </div>
              <template v-if="planInfo.change.upgrade_cost">
                <h2 class="mb-1">
                  {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(planInfo.change.upgrade_cost) }}
                </h2>
                <h5 class="text-muted">
                  <template v-if="planInfo.change.upgrade_cost > 1.00">
                    {{$t('billing.subscription.plans.period.immediately')}}
                  </template>
                  <template v-else>
                    {{$t('billing.subscription.plans.period.next_bill')}}
                  </template>
                </h5>
              </template>
              <template v-else>
                <h5 class="text-muted">
                  {{ $t('billing.checkout.summary.free.disclaimer') }}
                </h5>
              </template>
              <hr>
            </template>

            <div class="row mt-2 justify-content-center">
              <div class="col-lg-12 col-sm-12 mb-1">
                <b-form-checkbox
                    v-model="termsAccepted"
                    class="custom-checkbox mb-3"
                >
                  <span class="text-white">
                    {{ $t('billing.checkout.overview.legal.confirmation') }}
                  </span>
                </b-form-checkbox>
              </div>
              <div class="col-lg-12 col-sm-12 mb-1" v-if="!planInfo.instant_activation">
                <b-form-checkbox
                    v-model="withdrawalWaived"
                    class="custom-checkbox mb-3"
                >
                  <span class="text-white">
                    {{ $t('billing.checkout.overview.withdrawal.confirmation') }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>

            <div class="text-center mb-3">
              <button class="btn btn-lg" :class="{'btn-primary': canContinue(), 'btn-warning': !canContinue(), 'disabled': !canContinue() || processing}" :disabled="!canContinue() || processing" v-on:click="doContinue()">
                <h5 class="text-uppercase mb-0">
                  <half-circle-spinner
                      v-if="processing"
                      :animation-duration="900"
                      :size="18"
                      class="align-middle d-inline-block"
                  />
                  {{ $t('billing.checkout.continue') }}
                </h5>
              </button>
            </div>

            <div class="row mt-2 justify-content-center">
              <div class="col-lg-12 col-sm-12 mb-2">
                <a href="https://cftools.com/legal/terms-of-use" rel="noopener" target="_blank" class="btn btn-block btn-label btn-light btn-xs btn-sm">
                  <i class="far fa-external-link label-icon mr-1"></i>
                  {{ $t('billing.checkout.overview.legal.terms') }}
                </a>

                <a href="https://cftools.com/legal/dpa" rel="noopener" target="_blank" class="btn btn-block btn-label btn-light btn-xs btn-sm">
                  <i class="far fa-external-link label-icon mr-1"></i>
                  {{ $t('billing.checkout.overview.legal.dpa') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>
  </div>
</template>
